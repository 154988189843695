import CryptoJS from 'crypto-js';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const salt = '56ac1423';
export const encryptData = (text) =>{
    return CryptoJS.AES.encrypt(text, salt).toString();
}                            
    
export const decryptData = (ciphertext) => {
    const decrypted = CryptoJS.AES.decrypt(ciphertext, salt);
    if (decrypted) {
      try {
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return str;
        } else {
          return 'no text';
        } 
      } catch (e) {
        return 'wrong salt';
      }
    }
    return 'unknow error';
}

export const exportToCSV = (apiData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
  FileSaver.saveAs(data, fileName + ".xlsx");
};

// export const URL_WEB_BACKEND = 'http://localhost:8080';
// export const URL_WEB_FORNTEND = 'http://localhost:3000';

export const URL_WEB_BACKEND = 'https://bepagar.lpsemarang.or.id';
export const URL_WEB_FORNTEND = 'https://pagar.lpsemarang.or.id';