/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import axios from 'axios';
import { URL_WEB_BACKEND, decryptData } from "./SecureUtil"
import './scss/style.scss'

const App  = () => { 

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )
  
  // Containers
  const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
  
  // Pages
  const Publik = React.lazy(() => import('./views/pages/publik/Publik'))
  const Login = React.lazy(() => import('./views/pages/login/Login'))
  const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
  const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
  
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("authinfo") !== null) {
      const authdata = decryptData(localStorage.getItem("authinfo"))
      axios.get(URL_WEB_BACKEND+'/profile', {
        headers: {
          'Authorization': 'Bearer '+authdata
        }
      }).then(res => {
        if(res.data !== null){
          setIsLogged(true)
        }else{
          window.location.replace('/')
        }
      }).catch(error => {
          console.log(error)
          // for(let i = 0; i < 2; i++){
          //   window.location.reload()
          // }
      })
    } else {
      setIsLogged(false)
    }
  }, [isLogged]);
  
  const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      !isLogged
        ? <Component {...props} />
        : <Redirect to='/' />
    )} />
  );
  
  const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      isLogged
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  );

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
            <Route exact path="/caritau" name="Pencarian Data Publik" component={Publik} />
            <UnauthenticatedRoute exact path="/login" name="Halaman Login" component={Login} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <AuthenticatedRoute path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default App
